import Vue from "vue";
import crudMutations from "@/store/templates/crud/mutations";
import { SET_MODEL } from "@/store/templates/crud/mutation-types";
import { setEntities } from "@/store/templates/entities";
import { clone, cloneDeep } from "lodash";

export default {
  ...crudMutations,
  [SET_MODEL](state, { entities, id }) {
    const initialModel = cloneDeep(entities.entity[id]);

    // clone enough for localizations, as they only contain string properties. Object.assign({}, target) would work
    // too, but if target is null or undefined this would give an empty object rather than null or undefined.
    initialModel.label = clone(entities.localization[initialModel.label]);
    initialModel.countries = initialModel.countries.map(countryId =>
      clone(entities.country[countryId])
    );

    Vue.set(state, "initialModel", initialModel);
    Vue.set(state, "model", cloneDeep(initialModel));

    setEntities(state, entities);
  },
  setName(state, name) {
    state.model.name = name;
  },
  setLabel(state, label) {
    console.log("updating description label", label);
    state.model.label = label;
  },
  setDescriptionFallback(state, descriptionFallback) {
    console.log("updating description fallback", descriptionFallback);
    Vue.set(state.model, "descriptionFallback", descriptionFallback);
  },
  setType(state, type) {
    state.model.type = type;
  },
  setParent(state, parent) {
    state.model.parent = parent;
  },
  setMaxOffersPerLead(state, maxOffersPerLead) {
    state.model.maxOffersPerLead = parseInt(maxOffersPerLead);
  },
  setCountryPrice(state, { countryId, price }) {
    const country = state.model.countries.find(
      country => country.id === countryId
    );
    country.leadPrice = price;
  }
};
